import * as React from "react"
import {Typography} from "@mui/material";
import BlogPost from "../../components/blog-post";


// markup
const BlogPostPage = () => {
    return (
        <BlogPost title={'How is a jury selected?'} subtitle={'Sept 2, 2020'}>

            <br/>
            <Typography variant={"h5"} color={"primary"}>Selecting an impartial jury</Typography>
            <Typography>The 6th Amendment to the Constitution guarantees the right to a trial by an impartial jury. Jury selection (or voir dire) is the process by which potential jurors are questioned to select a jury for a trial. Attorneys and judges ask questions to determine if jurors are competent, unbiased, and capable of serving.</Typography>
            <br/>
            <Typography variant={"h5"} color={"primary"}>Dismissing jurors from the jury pool</Typography>
            <Typography>A pool of potential jurors is called to court via jury summons. The jury pool (also called venire) is typically much larger than the actual number of jurors that will sit in the jury box, which is typically six to twelve people, plus alternates. The potential jurors are then asked a series of questions to determine if any of them is not legally qualified, or if jury service would cause an undue hardship. Anyone falling into these categories is then dismissed from service.</Typography>
            <br/>
            <Typography>The prosecution and defense next take turns asking questions to potential jurors with the goal of determining who to dismiss. Attorneys can dismiss jurors by challenging them for cause or by issuing a peremptory challenge. If it can be shown that a juror has a bias, they can be challenged for cause. This type of challenge is typically unlimited. Attorneys may also issue a peremptory challenge, which means they can dismiss a juror without giving a reason, however they cannot discriminate based on race, gender, etc. There are only a set number of peremptory challenges, the number of which depends on the state and the type of case being tried.</Typography>

            <br/>
            <Typography variant={"h5"} color={"primary"}>Batson-Wheeler motion</Typography>
            <Typography>During the selection process an attorney may make a motion that one of the peremptory challenges made by the other side was based on discrimination. This is called a Batson-Wheeler motion. If the court decides that a juror was dismissed based on discrimination, the juror may be called back, or the jury selection may even be restarted again with an entirely new jury pool. If no such motion is taken the remaining persons in the jury pool are dismissed, the jury is placed in the jury box, and the next phase of the trial commences.</Typography>

        </BlogPost>
    );
};

export default BlogPostPage;
